import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// SCROLL CHECK
//------------------------------------//

let scrollPos = 0;
function checkPosition() {
	let windowY = window.scrollY;
	if (windowY < scrollPos) {
		// Scrolling UP
		document.body.classList.remove('is-scroll');
	} else {
		// Scrolling DOWN
		if (windowY < 300) {
			document.body.classList.remove('is-scroll');
		} else {
			document.body.classList.add('is-scroll');
		}
	}

	if (windowY < 300 && document.body.classList.contains('home')) {
		document.body.classList.add('is-top');
	} else {
		document.body.classList.remove('is-top');
	}
	scrollPos = windowY;
}

checkPosition();

function debounce(func, wait = 10, immediate = true) {
	let timeout;
	return function () {
		let context = this,
			args = arguments;
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

window.addEventListener('scroll', debounce(checkPosition));

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//
const tabTriggers = document.querySelectorAll('.tabs-nav span');

[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchTab);
});

function switchTab(e) {
	let targetTab = e.currentTarget.dataset.tab;

	removeActive('.tab[data-active]');
	removeActive('.tabs-nav span[data-active]');

	e.currentTarget.dataset.active = true;
	document.querySelector(`.tab[data-tab='${targetTab}']`).dataset.active = true;
}

//------------------------------------//
// TABS - SELECT
//------------------------------------//
const inputTrigger = document.querySelector('select.tabs-nav');

inputTrigger &&
	inputTrigger.addEventListener('change', (e) => {
		e.preventDefault();

		let elTarget = e.currentTarget.value;

		removeActive('.tab[data-active]');

		//trigger.dataset.active = true;
		document.querySelector(
			`.tab[data-tab='${elTarget}']`
		).dataset.active = true;
	});

//------------------------------------//
// Accordion
//------------------------------------//
const accordionTriggers = document.querySelectorAll(
	'[data-list="accordion"] .item-title'
);

[...accordionTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchActive);
});

function switchActive(e) {
	removeActive('[data-list="accordion"] .item[data-active]');

	e.currentTarget.parentNode.dataset.active = true;
}

//------------------------------------//
// SWIPERS
//------------------------------------//
const swiperIcons = new Swiper('.swiper-icons', {
	// Optional parameters
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	spaceBetween: 64,

	// Navigation arrows
	navigation: {
		nextEl: '.btn-next',
		prevEl: '.btn-prev'
	}
});
